.iframe-style {
    position: absolute;
    top: 3%;
    left: 3%;
    width: 94%;
    height: 94%;
    border-radius: 50px;
    border: none;
    display: block;
    margin: auto;
}

.iframe-contenedor {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}